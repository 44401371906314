import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import useObfuscateUrl from '../../../hooks/useObfuscateUrl'
import replaceHife from '../../../utils/replaceHife'
import KLink from '../../Core/KLink'
import Slider from '../../Core/Slider'
import * as stylesSummer from '../Summer/summer.module.scss'
import * as styles from './partner.module.scss'

interface PartnerProps {
  data: {
    title: string
    subtitle: string
    link?: Array<{
      url: string
      name: string
      type: string
    }>
    partnersList: Array<{
      content: {
        title: string
        link: { url: string }
        logo: { src: string; media: { alt: string }; gatsbyImageData: any }
      }
    }>
  }
}

export default function Partner(props: PartnerProps) {
  const { data } = props
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const { encode, navigate } = useObfuscateUrl()

  return (
    <section
      ref={ref}
      className={`s-partner ${styles.partnerWrap} animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div className="container">
        <div className={`${stylesSummer.summer} ${styles.partner}`}>
          <div className={styles.partner__inner}>
            <div className={styles.partner__txt}>
              <h2
                className={`${styles.partner__title} partner-title`}
                dangerouslySetInnerHTML={{ __html: replaceHife(data.title) }}
              ></h2>

              <p className={styles.partner__pg} dangerouslySetInnerHTML={{ __html: replaceHife(data.subtitle) }}></p>
            </div>
            <div className={styles.partner__logos}>
              <Slider
                pagination={false}
                navigation={false}
                slidesPerView="auto"
                spaceBetween={32}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                  },
                }}
                data={data.partnersList.map((p, index) => ({
                  content: (
                    <div
                      data-o={encode(p.content.link?.url)}
                      onClick={() => navigate(p.content.link?.url)}
                      className={`${styles.partner__logo} cursor-pointer`}
                    >
                      <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                          __html: JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Organization',
                            url:
                              p.content.link?.url || site.siteMetadata.siteUrl,
                            logo: p.content.logo.src,
                          }),
                        }}
                      ></script>
                      {p.content.logo && p.content.logo.gatsbyImageData ? (
                        <GatsbyImage
                          image={p.content.logo.gatsbyImageData}
                          alt={p.content.logo?.media?.alt}
                        />
                      ) : (
                        <img
                          src={p.content.logo.src}
                          key={`i-${index}`}
                          loading="lazy"
                          alt={p.content.title}
                        />
                      )}
                    </div>
                  ),
                }))}
              />
            </div>
            {data.link && data.link[0] && (
              <KLink
                to={data.link[0].url}
                hover="#142336"
                type={data.link[0].type}
                label={data.link[0].name}
                btnType="dark"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
