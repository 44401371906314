import React from 'react'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import * as styles from './twist.module.scss'
import { useInView } from 'react-intersection-observer'
import { navigate } from 'gatsby'
import SummerIllus from '../../Illus/SummerIllus'
import replaceHife from '../../../utils/replaceHife'
import useGtmEvent from '../../../hooks/useGtmEvent'

interface TwistProps {
  data: {
    title: string
    subtitle: string
    mainColor: string
    link: Array<{
      url: string
      name: string
      type: string
      target?: string
    }>
    linkEvent?: string
    illustration?: {
      src: string
      gatsbyImageData: any
    }
  }
}

export default function Twist(props: TwistProps) {
  const { data } = props
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const onClickPush = (e: any) => {
    const clickTarget = e.target
    // if the click target is a link or the parent of the target is a link, do nothing
    console.log(clickTarget.tagName, clickTarget.parentElement.tagName)
    if (
      clickTarget.tagName === 'A' ||
      clickTarget.parentElement.tagName === 'A'
    ) {
      return
    }

    console.log(e.target)
    if (data.link && data.link[0]) {
      if (!/\/popins\//.test(data.link[0].url)) {
        if (data.linkEvent) {
          useGtmEvent(data.linkEvent)
        }

        if (data.link[0].type === 'EXTERNAL') {
          window.open(data.link[0].url, data.link[0].target || '_self')
        } else {
          navigate(data.link[0]?.url || '')
        }
      }
    }
  }

  return (
    <section
      ref={ref}
      className={`s-twist text-center ${styles.twistSection} animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div
        className={`${styles.twist} twist-wrapper`}
        style={
          {
            '--bg-twist': `#${data.mainColor || '60B2A2'}`,
          } as React.CSSProperties
        }
      >
        <div className="container" onClick={onClickPush}>
          <div>
            <Text
              tag="h2"
              as="h1"
              className="twist-title"
              dangerouslySetInnerHTML={{ __html: replaceHife(data.title) }}
            ></Text>
            <Text
              tag="div"
              as="bodyH1"
              dangerouslySetInnerHTML={{ __html: replaceHife(data.subtitle) }}
              className={styles.twistDesc}
            ></Text>
            {data.link && data.link[0] && (
              <KLink
                to={data.link[0]?.url || ''}
                label={data.link[0]?.name || 'Je participe'}
                type={data.link[0]?.type}
                target={data.link[0]?.target}
                btnType="white"
              />
            )}
          </div>
          <div className={`${styles.twistIllustration} hidden md:block`}>
            {data.illustration && data.illustration.src ? (
              <img
                src={data.illustration.src}
                alt={data.title}
                width={163}
                height={163}
              />
            ) : (
              <SummerIllus />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
