import React from 'react'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import * as styles from './discovery.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from '../../../../plugins/gatsby-plugin-intl-kley'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import useLinkLocalized from '../../../hooks/useLinkLocalized'

interface DiscoveryProps {
  data: {
    title: string
    subtitle: string
    residences: Array<{
      name: string
      shortName: string
      _url: string
      _urls: {
        fr: string
        en_us: string
      }
      imageCover: any
      comingSoon?: Boolean
    }>
    illus: {
      src: string
    }
    link: Array<{
      url: string
      name: string
    }>
  }
}

export default function Discovery(props: DiscoveryProps) {
  const {
    data: { title, subtitle, residences, illus, link },
  } = props
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })
  const { t } = useTranslation('common')

  return (
    <section
      ref={ref}
      className={`${styles.discovery} discovery animate ${
        inView ? 'in-view' : ''
      }`}
    >
      <div className={`container ${styles.discovery__container}`}>
        <div className={styles.discovery__content}>
          <Text
            tag="h2"
            className="discovery-title"
            dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
          ></Text>
          <Text
            tag="p"
            as="bodyH2"
            dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}
          ></Text>
          {link && link.length > 0 && (
            <KLink to={link[0].url} label={link[0].name} />
          )}

          <img src={illus.src} alt="" className={'hidden md:block'}/>
        </div>
        <div className={styles.discovery__items}>
          <ul>
            {residences &&
              residences.length &&
              residences.map((r, index) => (
                <li key={`s-${index}`}>
                  <div className={styles.discovery__loc}>
                    {r.comingSoon ? (
                      <Text
                        className={`${styles.discovery__place} c-turquoise`}
                        dangerouslySetInnerHTML={{
                          __html: replaceHife(r.shortName),
                        }}
                      ></Text>
                    ) : (
                      <Link
                        to={useLinkLocalized({ urls: r._urls })}
                        title=""
                        className={`${styles.discovery__place} c-turquoise`}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: replaceHife(r.shortName),
                          }}
                        ></span>
                      </Link>
                    )}
                    <div className={styles.discovery__residence}>
                      {r.imageCover && r.imageCover.gatsbyImageData ? (
                        <GatsbyImage
                          image={r.imageCover.gatsbyImageData}
                          alt={r.imageCover?.media?.alt}
                        />
                      ) : (
                        <img
                          src={r.imageCover ? r.imageCover.src : ''}
                          alt={r.name}
                          loading="lazy"
                        />
                      )}
                      {/* <KLink
                        to={r._url}
                        label={t('Decouvrir la résidence', 'common')}
                        type="white"
                      /> */}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
