import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Link } from '../../../../plugins/gatsby-plugin-intl-kley'
import React from 'react'
import useBreakpoint from '../../../hooks/useBreakpoint'
import useEllipsis from '../../../hooks/useEllipsis'
import KLink from '../../Core/KLink'
import Text from '../../Core/Text'
import BlockWith2Cols, { BlockWith2ColsProps } from '../BlockWith2Cols'
import LifeStyleSlider, { LifeStyleSliderItem } from './LifeStyleSlider'
import replaceHife from '../../../utils/replaceHife'
import VirtualVisitButton, {
  VirtualVisitButtonProps,
} from '../../Core/VirtualVisitButton'
import VideoProvider from '../../Core/VideoProvider'
import useLinkLocalized from '../../../hooks/useLinkLocalized'
import useGtmEvent from '../../../hooks/useGtmEvent'

interface LifestyleProps extends Omit<BlockWith2ColsProps, 'colorCombination'> {
  data: {
    title: string
    subtitle?: string
    slides: LifeStyleSliderItem[]
    colorCombination: string
    links: Array<{
      url: string
      name: string
    }>
    cover: any
    contentTypeAlias: string
    backgroundVimeoId?: string
  } & VirtualVisitButtonProps
  type?: 'slider' | 'single'
  uniqId?: string
}

const DESC = `Digital Nomad, entrepreneur, freelancer, slasher... Peu importe
comment vous vous définissez, vous cherchez un logement avec un
maximum d'intimité et de flexibilité sans vous prendre la tête. Ou
simplement vous cherchez à profiter d'espaces de vie pour
travailler, faire du sport, un afterwork...`

export default function Lifestyle(props: LifestyleProps) {
  const {
    data,
    reverse,
    withLink,
    type = 'single',
    className = '',
    uniqId,
  } = props
  const ellipsis = useEllipsis()
  const breakpoint = useBreakpoint()

  const desc = data.subtitle || DESC

  const colorCombination =
    data && data.colorCombination ? data.colorCombination.toLowerCase() : 'navy'

  const onTrackVirtualVisit = () => {
    useGtmEvent('visite_virtuelle')
  }

  return (
    <BlockWith2Cols
      id={data.contentTypeAlias}
      uniqId={uniqId}
      className={className}
      withLink
      reverse={reverse}
      mainContent={
        <>
          {type === 'single' ? (
            data.backgroundVimeoId ? (
              <VideoProvider
                source={data.backgroundVimeoId ? 'vimeo' : 'upload'}
                className={`c-video-hero`}
                data={{
                  contentUrl: data.backgroundVimeoId,
                  embedUrl: data.backgroundVimeoId,
                  poster:
                    data.cover && data.cover.gatsbyImageData
                      ? data.cover.gatsbyImageData
                      : null,
                }}
              />
            ) : data.cover && data.cover.gatsbyImageData ? (
              <GatsbyImage
                image={data.cover.gatsbyImageData}
                alt={data.cover?.media?.alt || ''}
              />
            ) : (
              <figure>
                <StaticImage
                  src="../../../images/student.png"
                  alt={data.title}
                  loading="lazy"
                />
              </figure>
            )
          ) : (
            <LifeStyleSlider slides={data.slides || []} />
          )}
        </>
      }
      bgContent={
        <>
          <Text
            tag="h2"
            className="c-typo-title title"
            dangerouslySetInnerHTML={{
              __html: replaceHife(
                data.title || 'UN LIEU DE VIE POUR TOUS LES LIFESTYLES'
              ),
            }}
          ></Text>
          <div>
            <Text
              tag="p"
              as="bodyH1"
              dangerouslySetInnerHTML={{
                __html: replaceHife(desc),
              }}
            ></Text>
            {data.links && data.links[0] && (
              <div>
                <KLink
                  to={data.links[0].url}
                  type={data.links[0].url.includes('http') ? 'EXTERNAL' : ''}
                  btnType="white"
                  hover="#f5aaa2"
                  label={data.links[0].name}
                />
              </div>
            )}
            {withLink && data.links && data.links[1] && (
              <Link
                to={useLinkLocalized({url: data.links[1].url})}
                 type={data.links[1].url.includes('http') ? 'EXTERNAL' : ''}
                title=""
                className="text-white underline inline-block mt-2 hover:no-underline hover:text-white"
              >
                {data.links[1].name}
              </Link>
            )}
            {data.virtualVisit && data.virtualVisitUrl && (
              <VirtualVisitButton {...data} tracking={onTrackVirtualVisit} />
            )}
          </div>
        </>
      }
      colorCombination={colorCombination}
    />
  )
}
